import React from "react";
import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import MuiSelect from "@mui/material/Select";

export const ShapeMenu = (props) => {
  const { t } = useTranslation();

  const Select = withStyles((theme) => ({
    select: {
      whiteSpace: "normal !important",
    },
  }))(MuiSelect);
  const {
    handleSelectRectangularShape,
    handleSelectCircleShape,
    handleSelectRoundedRectangularShape,
    shape,
    setShape,
  } = props;
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>{t("shape")}</InputLabel>
        <Select
          value={shape}
          autoWidth
          disableAutoFocusItem
          sx={{ fontSize: "12px", height: "60px" }}
          onChange={(event) => {
            if (event.target.value === "rectangular") {
              handleSelectRectangularShape();
              setShape("rectangular");
            } else if (event.target.value === "circle") {
              setShape("circle");
              handleSelectCircleShape();
            } else {
              setShape("roundedRectangular");
              handleSelectRoundedRectangularShape();
            }
          }}
          label={t("shape")}
        >
          <MenuItem
            id="rectangular"
            value="rectangular"
            style={{ minWidth: "100%" }}
          >
            {t("rectangular")}
          </MenuItem>
          <MenuItem id="circle" value="circle" style={{ minWidth: "100%" }}>
            {t("circle")}
          </MenuItem>
          <MenuItem
            id="roundeRectangle"
            value="roundedRectangular"
            style={{ minWidth: "100%" }}
          >
            {t("roundedRectangular")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
