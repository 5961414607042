import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const textStyle = {
  color: "white",
  margin: "25px 0px 25px 0px",
  fontSize: "17px",
};

const titleStyle = {
  color: "black",
  fontSize: "50px",
  margin: "50px 0px 0px 0px",
};

const questionStyle = {
  color: "#FFBF00",
  fontSize: "30px",
  margin: "50px 0px 25px 0px",
};

const Faq = () => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent={"center"}>
      <Helmet>
        <title>PrintWorks Intrebari Frecvente</title>
        <meta
          name="description"
          content="
          Bine ați venit pe pagina noastră web dedicată intrebarilor frecvente despre configuratorul nostru de stickere! Aici, puteti sa gasiti cele mai importante informatii in legatura cu ce materiale folosim, cum va puteti configura stickerul si cum decurge livrarea acestora."
        />
        <meta
          name="title"
          content="Intrebari frecvente despre configuratorul nostru de stickere. Gasiti raspunsuri la neclaritatile dumneavostra."
        />
      </Helmet>
      <Grid item xs={8}>
        <Grid
          item
          xs={5}
          md={2}
          sx={{
            width: "auto",
            backgroundColor: "#FFBF00",
            borderRadius: "12px",
            textAlign: "center",
          }}
        >
          <Typography style={titleStyle}>FAQ</Typography>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>
          {t("faq.how.to.upload.file")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.how.to.upload.file")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.diecut.kisscut")}</Typography>
        <Typography style={textStyle}>{t("description.faq.diecut")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.kisscut")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.max.dimension")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.max.dimension")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.max.dimension1")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.sticker.types")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.sticker.types")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.sticker.types1")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.image.quality")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.image.quality")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.image.quality1")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.image.quality2")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.image.quality3")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}> {t("faq.return.order")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.return.order1")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.return.order2")}
        </Typography>
      </Grid>
      {/* <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.change.order")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.change.order")}
        </Typography>
      </Grid> */}
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.cancel.policy")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.cancel.policy1")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.cancel.policy2")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.cancel.policy3")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.whatis.pvc")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.whatis.pvc")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>{t("faq.delivery.time")}</Typography>
        <Typography style={textStyle}>
          {t("description.faq.delivery.time")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography style={questionStyle}>
          {t("faq.delivery.method")}
        </Typography>
        <Typography style={textStyle}>
          {t("description.faq.delivery.method")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Faq;
