import * as React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { colors } from "..";
import Anaf1 from "../assets/anpc_1.webp";
import Anaf2 from "../assets/anpc_2.webp";
import NTPLogo from "ntp-logo-react";
import { useTranslation } from "react-i18next";
import { AcceptTermsModal } from "../components/PaymentForm/components/AcceptTermsModal";

export const Footer = (props) => {
  const { t } = useTranslation();
  const [openAcceptTermsModal, setOpenAcceptTermsModal] = React.useState(false);
  const TikTokIcon = () => {
    return (
      <svg
        focusable="false"
        viewBox="0 0 50 50"
        fill="white"
        width="40%"
        height="40%"
        style={{
          "&:hover": {
            backgroundColor: "red",
          },
        }}
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };
  return (
    <BottomNavigation
      showLabels
      sx={{
        background: colors.main,
        height: "auto",
        marginTop: "300px",
        padding: "20px",
      }}
    >
      <Grid container justifyContent="center">
        <AcceptTermsModal
          open={openAcceptTermsModal}
          handleClose={() => setOpenAcceptTermsModal(false)}
        />
        <Grid item container xs={12} justifyContent="center">
          <BottomNavigationAction
            sx={{ color: "white" }}
            target="_blank"
            href="https://www.instagram.com/printworks_romania/"
            icon={<InstagramIcon />}
          />
          <BottomNavigationAction
            style={{
              "&:hover": {
                backgroundColor: "blue !important",
              },
            }}
            sx={{ color: "white" }}
            target="_blank"
            href="https://www.facebook.com/profile.php?id=61553002409289"
            icon={<FacebookIcon />}
          />
          <BottomNavigationAction
            target="_blank"
            sx={{ color: "white" }}
            href="https://www.tiktok.com/@printworksstudio"
            icon={<TikTokIcon />}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          padding="20px"
        >
          <Grid item container xs={12} md={3} justifyContent="flex-start">
            <Grid item xs={12} md={8}>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                {t("contact.name")}: Tasweb SRL
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                CUI: 48726629
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                Reg. Com: J12/3708/2023
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                {t("contact.phone")}: 0749210835
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                Email: comenzi.printworks@gmail.com
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
              >
                Adresa: Cluj-Napoca, Str. Paris 70
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            justifyContent="center"
            marginTop="10px"
          >
            <Grid
              item
              container
              xs={12}
              md={2}
              marginRight="10px"
              justifyContent="center"
            >
              <a
                href="https://anpc.ro/ce-este-sal/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Anaf1} alt="anaf1" />
              </a>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              md={2}
              marginRight="10px"
            >
              <NTPLogo color="#14499e" version="vertical" secret="133446" />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              md={2}
              marginRight="10px"
            >
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Anaf2} alt="anaf2" />
              </a>
            </Grid>
            <Grid item xs={12} md={6} container justifyContent="center">
              <Button
                sx={{ color: "white", fontSize: "12px" }}
                textAlign="center"
                variant="h5"
                onClick={() => setOpenAcceptTermsModal(true)}
              >
                Termeni si conditii
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
};
