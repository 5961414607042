import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  changeAddressValue,
  changeBillingValue,
  saveToDrive,
  selectBillingAddress,
  selectLoadingState,
  selectSelectedAddress,
  selectStickerData,
} from "../Sticker/createStickerSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingBackdrop } from "../Components/LoadingBackdrop";
import { useNavigate } from "react-router-dom";
import {
  changeValidationValue,
  getUserAddreses,
  selectAddressesState,
  selectBillingState,
} from "./userAddressesSlice";
import { DeliveryForm } from "./components/DeliveryForm";
import { UserAddreses } from "./components/UserAddresses";
import { BillingForm } from "./components/BillingForm";
import { ACCESS_TOKEN } from "../Auth/constants";
import { AcceptTermsModal } from "./components/AcceptTermsModal";
import {
  resetTotalPrice,
  selectDiscountCode,
  selectDiscountValue,
  selectTotalPrice,
} from "../Pricing/pricingSlice";
import { colors } from "../..";
import { PaymentMethodForm } from "./components/PaymentMethodForm";

export const PaymentForm = (props) => {
  const [deliveryMethod, setDeliveryMethod] = useState("curier");
  const [sameBillingAddress, setSameBillingAddress] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptCardPayment, setAcceptCardPayment] = useState(false);
  const [openAcceptTermsModal, setOpenAcceptTermsModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stickerState = useSelector(selectStickerData);
  const loadingState = useSelector(selectLoadingState);
  const selectedAddress = useSelector(selectSelectedAddress);
  const selectedBillingAddress = useSelector(selectBillingAddress);
  const totalPrice = useSelector(selectTotalPrice);
  const discountValue = useSelector(selectDiscountValue);
  const navigate = useNavigate();
  const discountCode = useSelector(selectDiscountCode);
  const addresses = useSelector(selectAddressesState);
  const billingAddresses = useSelector(selectBillingState);

  const validate = () => {
    dispatch(changeValidationValue({ key: "terms", value: !acceptTerms }));
    dispatch(
      changeValidationValue({
        key: "address",
        value: !selectedAddress,
      })
    );
    dispatch(
      changeValidationValue({
        key: "billing",
        value: !selectBillingAddress || !sameBillingAddress,
      })
    );

    return !disabledButton();
  };

  const handleSendSticker = async () => {
    let payPage = window.open("", "_blank");
    payPage.document.write("Loading payment form...");
    if (validate()) {
      await dispatch(
        saveToDrive({
          stickers: stickerState.stickers,
          totalPrice: (totalPrice * ((100 - discountValue) / 100)).toFixed(1),
          userInformation: selectedAddress ? selectedAddress : {},
          userBilling: sameBillingAddress
            ? selectedAddress
            : selectedBillingAddress,
          hasCourier: deliveryMethod === "curier",
          discountCode: discountCode.toUpperCase(),
        })
      ).then((res) => {
        payPage.location.href = res.payload.url;
      });
      dispatch(resetTotalPrice());
      navigate("/history");
    }
  };

  const fetchAndSelectAddreses = async () => {
    await dispatch(getUserAddreses()).then((res) => {
      res &&
        dispatch(changeAddressValue(res.payload.filter((r) => !r.billing)[0]));
      res &&
        dispatch(changeBillingValue(res.payload.filter((r) => r.billing)[0]));
    });
  };

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      navigate("/login", { state: { route: "/paymentForm" } });
    } else {
      fetchAndSelectAddreses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addresses &&
      Object.keys(addresses).length === 0 &&
      dispatch(changeAddressValue(addresses[0]));
    billingAddresses &&
      Object.keys(billingAddresses).length === 0 &&
      dispatch(changeBillingValue(billingAddresses[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledButton = () => {
    if (!acceptCardPayment) {
      return true;
    }
    if (!acceptTerms) {
      return true;
    }

    if (deliveryMethod === "curier") {
      if (!(selectedAddress && Object.keys(selectedAddress).length !== 0)) {
        return true;
      }

      if (
        !(
          (selectedBillingAddress &&
            Object.keys(selectedBillingAddress).length !== 0) ||
          sameBillingAddress
        )
      ) {
        return true;
      }
    } else {
      if (
        selectedBillingAddress === undefined ||
        (selectedBillingAddress &&
          Object.keys(selectedBillingAddress).length === 0)
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - 110px)` }}
      justifyContent="center"
      marginTop="50px"
      alignContent="start"
    >
      <AcceptTermsModal
        open={openAcceptTermsModal}
        handleClose={() => setOpenAcceptTermsModal(false)}
      />
      <LoadingBackdrop isLoading={loadingState.saveToDrive} />
      <Grid container item xs={10} spacing={1} marginTop="12px">
        <DeliveryForm
          deliveryMethod={deliveryMethod}
          setDeliveryMethod={setDeliveryMethod}
        />

        <PaymentMethodForm
          acceptCardPayment={acceptCardPayment}
          setAcceptCardPayment={setAcceptCardPayment}
        />
        {/* addrese de livrare */}
        {deliveryMethod === "curier" && (
          <UserAddreses
            sameBillingAddress={sameBillingAddress}
            setSameBillingAddress={(value) => setSameBillingAddress(value)}
          />
        )}

        {/* addrese de facturare */}
        {(!sameBillingAddress || deliveryMethod !== "curier") && (
          <BillingForm />
        )}
        <Grid item container xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="dark"
                sx={{ color: colors.customYellow }}
                checked={acceptTerms}
                onChange={(event) => setAcceptTerms(event.target.checked)}
              />
            }
            label={
              <Typography
                style={{ color: "white" }}
                onClick={() => setOpenAcceptTermsModal(true)}
              >
                {t("acceptTerms")}
              </Typography>
            }
          />
        </Grid>

        <Grid item container xs={12} justifyContent="center">
          <Button
            disabled={disabledButton()}
            onClick={() => handleSendSticker()}
            variant="contained"
            color="dark"
          >
            {t("send.order")}
          </Button>
        </Grid>
        <Grid item container xs={12} justifyContent="start">
          <Typography color="error">{t("condition.send.order")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
