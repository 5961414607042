import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import storage from 'redux-persist-indexeddb-storage';

import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: 'root',
  storage: storage('Printworks'),
  blacklist: ["history", "addresses"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export const persistor = persistStore(store);

export default store;
