import { Grid, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistory,
  selectHistoryData,
  selectLoadingHistoryState,
} from "./orderHistorySlice";
import { LoadingBackdrop } from "../Components/LoadingBackdrop";
import { OrderHistoryRow } from "./components/OrderHistoryRow";
import { useTranslation } from "react-i18next";
import { ImageModal } from "./components/ImageModal";
import { ACCESS_TOKEN } from "../Auth/constants";
import { useNavigate } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import { colors } from "../../index";

export const OrderHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const historyState = useSelector(selectHistoryData);
  const loading = useSelector(selectLoadingHistoryState);
  const [modalImage, setModalImage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      navigate("/login", { state: { route: "/history" } });
    } else {
      dispatch(getHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - 110px)` }}
      justifyContent="center"
    >
      <Grid item container xs={10} marginTop="20px" alignItems="center">
        <HistoryIcon style={{ color: colors.customYellow, fontSize: "40px" }} />
        <Typography fontSize="35px" padding="20px" color={colors.customYellow}>
          {t("header.history")}
        </Typography>
      </Grid>
      <LoadingBackdrop isLoading={loading.getHistory} />
      <ImageModal
        open={openModal}
        image={modalImage}
        handleClose={() => setOpenModal(false)}
      />
      <Grid item xs={8}>
        <Typography color={colors.customYellow}>
          {t("change.order.status")}
        </Typography>
      </Grid>

      {historyState?.history?.length > 0 ? (
        <Grid
          container
          item
          xs={10}
          alignContent="start"
          sx={{
            border: "5px solid #FFBF00",
            borderRadius: "12px",
            width: "auto",
            height: "fit-content",
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "50px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
            style={{
              overflow: "auto",
              borderRadius: "6px",
              maxHeight: "72vh",
              width: "100%",
            }}
          >
            <Grid item container xs={12} justifyContent="center">
              {historyState?.history?.length > 0 &&
                historyState?.history?.map((order, index) => (
                  <OrderHistoryRow
                    key={index}
                    handleOpenModal={() => setOpenModal(true)}
                    handleSetModalImage={(image) => setModalImage(image)}
                    orderNumber={order.idorder}
                    price={order.price}
                    date={order.date}
                    orderStatus={order.status}
                    parentFileName={order.drive_directory_parent_name}
                    subOrders={order.subOrders}
                  />
                ))}
            </Grid>
          </Box>
        </Grid>
      ) : (
        <Grid item container xs={12} justifyContent="center">
          <Typography sx={{ color: "white" }}>{t("no.orders")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
