import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequestHistory } from "../../api";
import { displayToastError } from "../../utils/ErrorBoudary/errorBoudarySlice";

export const getHistory = createAsyncThunk(
  "stickers/getHistory",
  // eslint-disable-next-line no-empty-pattern
  async (data, { rejectWithValue, dispatch }) => {
    const response = await getRequestHistory();
    if (!response.error) {
      return response;
    } else {
      dispatch(displayToastError(response.detail));
      rejectWithValue("Cannot GET history");
    }
  }
);

export const orderHistorySlice = createSlice({
  name: "history",
  initialState: {
    formData: {
      history: [],
      loading: {
        getHistory: false,
      },
    },
  },

  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.formData.history = action.payload;
      state.formData.loading.getHistory = false;
    });
    builder.addCase(getHistory.pending, (state, action) => {
      state.formData.loading.getHistory = true;
    });
    builder.addCase(getHistory.rejected, (state, action) => {
      state.formData.loading.getHistory = false;
    });
  },
});

export const selectHistoryData = (state) => state.history.formData;
export const selectLoadingHistoryState = (state) =>
  state.history.formData.loading;

export default orderHistorySlice.reducer;
