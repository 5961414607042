import React from "react";
import { Button, ButtonGroup, Grid, useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderHorizontalIcon from "@mui/icons-material/BorderHorizontal";
import BorderVerticalIcon from "@mui/icons-material/BorderVertical";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";

export const ImageActionButtons = (props) => {
  const {
    remove,
    flipVertical,
    flipHorizontal,
    centerVertical,
    centerHorizontal,
  } = props;

  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Grid
      container
      item
      xs={12}
      md={1}
      position="absolute"
      left="12px"
      sx={{
        top: { xs: "10%", md: "40%" },
        justifyContent: { xs: "center", md: "none" },
      }}
    >
      <ButtonGroup orientation={matches ? "vertical" : "horizontal"}>
        <Button
          onClick={remove}
          color="dark"
          variant="contained"
          sx={{ border: "1px solid black" }}
        >
          <DeleteIcon />
        </Button>
        <Button
          onClick={flipVertical}
          color="dark"
          variant="contained"
          sx={{ border: "1px solid black" }}
        >
          <BorderHorizontalIcon />
        </Button>
        <Button
          onClick={flipHorizontal}
          color="dark"
          variant="contained"
          sx={{ border: "1px solid black" }}
        >
          <BorderVerticalIcon />
        </Button>
        <Button
          onClick={centerVertical}
          color="dark"
          variant="contained"
          sx={{ border: "1px solid black" }}
        >
          <AlignVerticalCenterIcon />
        </Button>
        <Button
          onClick={centerHorizontal}
          color="dark"
          variant="contained"
          sx={{ border: "1px solid black" }}
        >
          <AlignHorizontalCenterIcon />
        </Button>
      </ButtonGroup>
    </Grid>
  );
};
