import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { OrderHistoryRowDetails } from "./OrderHistoryRowDetails";

export const OrderHistoryRow = (props) => {
  const {
    orderNumber,
    subOrders,
    date,
    orderStatus,
    handleOpenModal,
    handleSetModalImage,
    parentFileName,
  } = props;
  const { t } = useTranslation();
  const statusColor =
    orderStatus === "NEPLATIT"
      ? "red"
      : orderStatus === "IN_PROGRESS"
      ? "#FFBF00"
      : "green";

  const handleImageClick = (image) => {
    handleOpenModal();
    handleSetModalImage(image);
  };

  const spanStyle = {
    margin: "0",
    // fontFamily: 'Open Sans MS,Open Sans,cursive',
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#000000",
  };

  return (
    <Grid item container xs={12} sx={{ borderBottom: "1px solid grey" }}>
      <Accordion
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ minHeight: "8vh" }}
        >
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item container xs={6} md={2} alignItems="center">
              <span style={spanStyle}>{`${t("orderNumber")}# `}</span> &nbsp;
              <Typography>{`${orderNumber}`}</Typography>
            </Grid>

            <Grid item container xs={6} md={2} alignItems="center">
              <span style={spanStyle}>{`${t("orderDate")}:`}</span> &nbsp;
              <Typography>{`${date?.split(" ")[0]}`}</Typography>
            </Grid>

            <Grid item container xs={6} md={2} alignItems="center">
              <span style={spanStyle}>{`${t("orderStatus")}: \u00A0 `} </span>
              <Typography color={statusColor}>
                {t(`status.${orderStatus}`)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {subOrders?.map((suborder) => (
            <OrderHistoryRowDetails
              key={suborder?.id_suborder}
              image={suborder?.jpgImage}
              handleImageClick={(image) => handleImageClick(image)}
              height={suborder?.heigth}
              width={suborder?.width}
              quantity={suborder?.quantity}
              material={suborder?.material}
              id={suborder?.id_suborder}
              isDieCut={suborder?.dieCut}
              fileNameChild={suborder?.driveFileName}
              fileNameParent={parentFileName}
              driveId={suborder?.driveId}
              wrapping={suborder?.wrapping}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
