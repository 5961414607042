import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteUserAddress,
  getUserInformation,
  saveNewUserAddress,
} from "../../api";
import { displayToastError } from "../../utils/ErrorBoudary/errorBoudarySlice";

export const getUserAddreses = createAsyncThunk(
  "userAddresses/getAddreses",
  async (data, thunkAPI) => {
    const response = await getUserInformation();
    if (!response.error) {
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.detail));
      thunkAPI.rejectWithValue("Cannot get addresses");
    }
  }
);
export const postNewAddress = createAsyncThunk(
  "userAddresses/postAddress",
  async ({ address }, thunkAPI) => {
    const response = await saveNewUserAddress(address);
    if (!response.error) {
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.detail));
      thunkAPI.rejectWithValue("Cannot post addresses");
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "userAddresses/deleteAddress",
  async ({ id }, thunkAPI) => {
    const response = await deleteUserAddress(id);
    if (!response.error) {
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.detail));
      thunkAPI.rejectWithValue("Cannot delete addresses");
    }
  }
);

export const userAddressesSlice = createSlice({
  name: "addresses",
  initialState: {
    formData: {
      savedAddresses: [],
      loading: {
        getUserAddreses: false,
        postNewAddress: false,
        deleteAddress: false,
      },
      validate: {
        address: false,
        billing: false,
        terms: false,
      },
    },
  },
  reducers: {
    changeAddressesValue: (state, action) => {
      state.formData.savedAddresses.push(action.payload);
    },

    changeValidationValue: (state, action) => {
      state.formData.validate[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAddreses.fulfilled, (state, action) => {
      state.formData.loading.getUserAddreses = false;
      state.formData.savedAddresses = action.payload;
    });
    builder.addCase(getUserAddreses.pending, (state, action) => {
      state.formData.loading.getUserAddreses = true;
    });
    builder.addCase(getUserAddreses.rejected, (state, action) => {
      state.formData.loading.getUserAddreses = false;
      state.formData.savedAddresses = [];
    });
    builder.addCase(postNewAddress.fulfilled, (state, action) => {
      state.formData.loading.postNewAddress = false;
      state.formData.savedAddresses = action.payload;
    });
    builder.addCase(postNewAddress.pending, (state, action) => {
      state.formData.loading.postNewAddress = true;
    });
    builder.addCase(postNewAddress.rejected, (state, action) => {
      state.formData.loading.postNewAddress = false;
    });
    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.formData.loading.deleteAddress = false;
      state.formData.savedAddresses = action.payload;
    });
    builder.addCase(deleteAddress.pending, (state, action) => {
      state.formData.loading.deleteAddress = true;
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.formData.loading.deleteAddress = false;
    });
  },
});

export const { changeAddressesValue, changeValidationValue } =
  userAddressesSlice.actions;
export const selectAddressesState = (state) =>
  state.addresses.formData.savedAddresses?.filter(
    (address) => !address.billing
  );
export const selectBillingState = (state) =>
  state.addresses.formData.savedAddresses?.filter((address) => address.billing);

export const selectValidationState = (state) =>
  state.addresses.formData.validate;

export const selectLoadingAddressesState = (state) =>
  state.addresses.formData.loading;

export default userAddressesSlice.reducer;
