import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentPriceValue,
  getStickerPrices,
  selectPricingList,
} from "./pricingSlice";
import { selectNoOnSheet } from "../Sticker/createStickerSlice";
import { DIE_CUT_PRICE, PROFIT } from "../Auth/constants";
import { useTranslation } from "react-i18next";

export const computeOrderedHistoryPrice = (
  quantity,
  material,
  dieCut,
  noOnSheet,
  wrapping,
  priceList
) => {
  const materialPrice =
    priceList.length > 0 &&
    priceList?.find((priceElement) => priceElement?.material === material)
      .price;
  const wrappingPrice =
    priceList.length > 0 &&
    priceList?.find((priceElement) => priceElement?.material === wrapping)
      .price;

  const dieCutPrice = dieCut ? DIE_CUT_PRICE : 0;
  const startingPrice =
    parseFloat(materialPrice) +
    parseFloat(wrappingPrice) +
    parseFloat(dieCutPrice);

  let discount = 0;

  if (quantity / noOnSheet >= 200) {
    discount = 0.6;
  } else if (quantity / noOnSheet >= 100) {
    discount = 0.5;
  } else if (quantity / noOnSheet >= 50) {
    discount = 0.4;
  } else if (quantity / noOnSheet >= 30) {
    discount = 0.3;
  } else if (quantity / noOnSheet >= 10) {
    discount = 0.2;
  }

  const realQuantity =
    quantity / noOnSheet < 10
      ? Math.ceil(quantity / noOnSheet) * noOnSheet
      : quantity;

  const pricePerSticker =
    ((PROFIT * startingPrice) / noOnSheet) * (1 - discount);

  const price = Math.ceil(
    realQuantity * pricePerSticker +
      Math.ceil(quantity / noOnSheet) * startingPrice
  );
  return price;
};

export const PricingList = (props) => {
  const {
    quantity,
    material,
    dieCut,
    dimension,
    anchorEl,
    handleClose,
    setQuantity,
    wrapping,
    isFolio,
  } = props;
  const { t } = useTranslation();
  const priceList = useSelector(selectPricingList);
  const noOnSheet = useSelector(selectNoOnSheet);
  const dispatch = useDispatch();
  const [discountTable, setDiscountTable] = useState([]);
  const discountCategories = [10, 30, 50, 100, 200];

  useEffect(() => {
    if (priceList.length === 0) {
      dispatch(getStickerPrices());
    }
    dimension > 0 && computePrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quantity,
    material,
    dieCut,
    dimension,
    noOnSheet,
    wrapping,
    priceList,
    isFolio,
  ]);

  const computePrice = () => {
    const materialPrice =
      priceList.length > 0 &&
      priceList?.find((priceElement) => priceElement?.material === material)
        .price;
    const wrappingPrice =
      priceList.length > 0 &&
      priceList?.find((priceElement) => priceElement?.material === wrapping)
        .price;

    const dieCutPrice = dieCut ? DIE_CUT_PRICE : 0;
    const folioPrice = isFolio
      ? priceList?.find((priceElement) => priceElement?.material === "Folio")
          .price
      : 0;

    const startingPrice =
      parseFloat(materialPrice) +
      parseFloat(wrappingPrice) +
      parseFloat(dieCutPrice) +
      parseFloat(folioPrice);

    let discount = 0;

    if (quantity / noOnSheet >= 200) {
      discount = 0.6;
    } else if (quantity / noOnSheet >= 100) {
      discount = 0.5;
    } else if (quantity / noOnSheet >= 50) {
      discount = 0.4;
    } else if (quantity / noOnSheet >= 30) {
      discount = 0.3;
    } else if (quantity / noOnSheet >= 10) {
      discount = 0.2;
    }

    const realQuantity =
      quantity / noOnSheet < 10
        ? Math.ceil(quantity / noOnSheet) * noOnSheet
        : quantity;

    const pricePerSticker =
      ((PROFIT * startingPrice) / noOnSheet) * (1 - discount);

    const price = Math.ceil(
      realQuantity * pricePerSticker +
        Math.ceil(quantity / noOnSheet) * startingPrice
    );

    dispatch(
      changeCurrentPriceValue(price),
      parseFloat(materialPrice),
      parseFloat(dieCutPrice)
    );
  };

  useEffect(() => {
    computePriceDiscountTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material, dimension, noOnSheet, dieCut, wrapping, isFolio]);

  const computePriceDiscountTable = () => {
    const materialPrice =
      priceList.length > 0 &&
      priceList?.find((priceElement) => priceElement?.material === material)
        .price;
    const wrappingPrice =
      priceList.length > 0 &&
      priceList?.find((priceElement) => priceElement?.material === wrapping)
        .price;
    const dieCutPrice = dieCut ? DIE_CUT_PRICE : 0;
    const folioPrice = isFolio
      ? priceList?.find((priceElement) => priceElement?.material === "Folio")
          .price
      : 0;
    const startingPrice =
      parseFloat(materialPrice) +
      parseFloat(wrappingPrice) +
      parseFloat(dieCutPrice) +
      parseFloat(folioPrice);

    let auxDiscountTable = [];
    discountCategories.forEach((quantity) => {
      let discount = 0;

      if (quantity === 200) {
        discount = 0.6;
      } else if (quantity === 100) {
        discount = 0.5;
      } else if (quantity === 50) {
        discount = 0.4;
      } else if (quantity === 30) {
        discount = 0.3;
      } else if (quantity === 10) {
        discount = 0.2;
      }

      let pricePerSticker = (PROFIT * startingPrice) / noOnSheet;

      const price = Math.ceil(
        quantity * noOnSheet * pricePerSticker +
          Math.ceil(quantity) * startingPrice
      );

      pricePerSticker = pricePerSticker * (1 - discount);

      const reducedPrice = Math.ceil(
        quantity * noOnSheet * pricePerSticker +
          Math.ceil(quantity) * startingPrice
      );

      auxDiscountTable.push({
        discountCategory: quantity * noOnSheet,
        reducedPrice: Math.floor(reducedPrice),
        fullPrice: price,
      });
    });

    setDiscountTable(auxDiscountTable);
  };

  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableAutoFocus
      transformOrigin={{ vertical: 310 }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography fontSize="12px">{t("discount.number")}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontSize="12px">
                {t("discount.reducedPrice")}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography fontSize="12px">{t("discount.fullPrice")}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discountTable.map((row) => (
            <TableRow
              key={row.discountCategory}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                handleClose();
                setQuantity(row.discountCategory);
              }}
            >
              <TableCell align="center">
                <Typography fontSize="12px">{row.discountCategory}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography fontSize="12px">{row.reducedPrice} RON</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  fontSize="12px"
                  sx={{ color: "red", textDecoration: "line-through" }}
                >
                  {row.fullPrice} RON
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Popover>
  );
};
