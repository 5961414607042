import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress } from "../userAddressesSlice";
import {
  changeAddressValue,
  changeBillingValue,
  selectBillingAddress,
  selectSelectedAddress,
} from "../../Sticker/createStickerSlice";

export const UserAddressItem = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedAddress = useSelector(selectSelectedAddress);
  const selectedBillingAddress = useSelector(selectBillingAddress);
  const { name, address, company, userType, billing, address_id, onSelect } =
    props;

  const handleDelete = async () => {
    if (selectedAddress?.id_user_info === address_id) {
      if (!billing) {
        dispatch(changeAddressValue({}));
      } else {
        dispatch(changeBillingValue({}));
      }
    }
    dispatch(deleteAddress({ id: address_id }));
    if (!billing) {
      dispatch(changeAddressValue({}));
    } else {
      dispatch(changeBillingValue({}));
    }
  };

  return (
    <Grid
      item
      container
      border="1px solid"
      borderColor={
        (!billing && selectedAddress?.id_user_info === address_id) ||
        (billing && selectedBillingAddress?.id_user_info === address_id)
          ? "green"
          : colors.customYellow
      }
      sx={{ background: colors.secondary }}
      marginBottom="12px"
      padding="12px"
      borderRadius="12px"
      onClick={() => onSelect()}
    >
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">{t("contact.name")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
      {billing && userType && (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">{t("company.data")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{company}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">{t("contact.address")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{address}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Button
          color="error"
          variant="outlined"
          onClick={(event) => {
            event.stopPropagation();
            handleDelete();
          }}
        >
          {t("delete")}
        </Button>
      </Grid>
    </Grid>
  );
};
