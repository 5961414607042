import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

const spanStyle = {
  margin: "0",
  // fontFamily: 'Open Sans MS,Open Sans,cursive',
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "1.5",
  color: "#000000",
};

export const StickerCard = (props) => {
  const { t } = useTranslation();
  const {
    image,
    height,
    width,
    quantity,
    material,
    price,
    index,
    isDieCut,
    handleOpenModal,
    handleRemoveSticker,
    handleSetModalImage,
    wrapping,
  } = props;
  const handleImageClick = (image) => {
    handleOpenModal();
    handleSetModalImage(image);
  };
  return (
    <Grid item container xs={11}>
      <Grid
        item
        container
        xs={12}
        sx={{
          background: "white",
          borderRadius: "12px",
          padding: "15px",
          border: "5px solid #FFBF00",
          maxHeight: { xs: "auto", md: "200px" },
        }}
        alignItems="center"
      >
        <Grid item container xs={6} md={2} alignContent="center">
          <img
            src={image}
            onClick={() => handleImageClick(image)}
            alt="Grafica sticker personalizat incarcata de utilizator"
            style={{ height: "100px", width: "100px" }}
          />
        </Grid>
        <Grid item container xs={6} md={4} direction="column">
          <Grid item container xs={12} justifyContent="center">
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("material")}:`}</span> &nbsp;
              <Typography>{t(`cart_${material}`)}</Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}> {`${t("height")}:`} </span> &nbsp;
              <Typography> {`${height.toFixed(2)} cm`} </Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("width")}:`}</span> &nbsp;
              <Typography>{`${width.toFixed(2)} cm`}</Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("wrapping")}:`}</span> &nbsp;
              <Typography>{t(`${wrapping}Wrapping`)}</Typography>
            </Grid>
            {isDieCut && (
              <Grid item container xs={10} alignContent="center">
                <Typography>{t("has.die.cut")}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container xs={3} md={2} alignContent="center">
          <span style={spanStyle}>{`${t("quantity")}:`}</span> &nbsp;
          <Typography>{quantity}</Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          md={2}
          justifyContent="center"
          alignContent="center"
        >
          <Typography>{price} RON</Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          md={2}
          justifyContent="center"
          alignContent="center"
        >
          <DeleteIcon
            sx={{ color: "red", fontSize: "40px" }}
            onClick={() => handleRemoveSticker(index)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
