import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Box,
  Toolbar,
  Container,
  Drawer,
  Divider,
  Typography,
  Grid,
} from "@mui/material";

import React, { useState } from "react";
import Flag from "react-world-flags";

import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors } from "..";
import { routes } from "../config/routes";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

import { selectUserData } from "../components/Auth/login/loginSlice";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { selectStickerData } from "../components/Sticker/createStickerSlice";
import { selectCurrentPrice } from "../components/Pricing/pricingSlice";
import Logo from "../assets/PrintWorks-Logo.svg";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { handleLogout } = props;
  const userState = useSelector(selectUserData);
  const stickerState = useSelector(selectStickerData);
  const currentPrice = useSelector(selectCurrentPrice);
  const [anchorLanguageMenu, setAnchorLanguageMenu] = useState(null);
  const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const languageOpen = !!anchorLanguageMenu;
  const profileOpen = !!anchorProfileMenu;
  const navigate = useNavigate();

  const handleLanguageClick = (event) => {
    setAnchorLanguageMenu(event.currentTarget);
  };
  const handleLanguageClose = () => {
    setAnchorLanguageMenu(null);
  };

  const handleProfileClick = (event) => {
    setAnchorProfileMenu(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorProfileMenu(null);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const showFlagDependingOnLanguage = () => {
    switch (i18n.language) {
      case "ro":
        return (
          <>
            <Flag code="RO" height="20" style={{ marginRight: "10px" }} />
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              {t("header.language.ro")}
            </Typography>
          </>
        );
      case "en":
        return (
          <>
            <Flag code="GB" height="20" style={{ marginRight: "10px" }} />
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              {t("header.language.en")}
            </Typography>
          </>
        );
      default:
        return;
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={1}
      sx={{
        flexWrap: "wrap",
        justifyContent: "space-between",
        background: colors.secondary,
        height: "50px",
      }}
    >
      <Box
        position="absolute"
        sx={{ display: { xs: "none", lg: "flex" } }}
        top="10px"
        left="40px"
      >
        <img
          src={Logo}
          alt="Printworks Logo: Site configurator de stickere"
          onClick={() => navigate("/")}
          style={{ width: "200px" }}
        />
      </Box>
      <Container maxWidth={false} sx={{ paddingRight: "0px !important" }}>
        <Toolbar
          sx={{ paddingRight: "0px !important", minHeight: "50px !important" }}
        >
          <Box sx={{ display: { xs: "flex", lg: "none" }, flexGrow: 1 }}>
            <DensityMediumIcon onClick={() => setOpenDrawer(!openDrawer)} />
            <Drawer
              anchor="left"
              width={300}
              open={openDrawer}
              PaperProps={{
                sx: {
                  background: colors.ligthGray,
                  width: "300px",
                },
              }}
              onClose={() => setOpenDrawer(false)}
            >
              <Grid
                item
                container
                sx={{ height: "70px" }}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={Logo}
                  alt="Printworks: Configurare stickere personalziate"
                  onClick={() => navigate("/")}
                  style={{ maxHeight: "70px" }}
                />
              </Grid>
              <Divider />
              {routes.map(
                (route) =>
                  route.showInMobile &&
                  !route.icon && (
                    <MenuItem
                      component={Link}
                      to={route.path}
                      key={route.title}
                      style={{
                        backgroundColor:
                          location.pathname === route.path
                            ? colors.customYellow
                            : colors.ligthGray,
                        textDecoration: "none",
                        color: colors.text,
                        fontSize: "24px",

                        marginBottom: "20px",
                      }}
                    >
                      {t(route.title)}
                    </MenuItem>
                  )
              )}
              {userState.authenticated ? (
                <MenuItem
                  onClick={handleLogout}
                  sx={{ color: "red" }}
                  style={{
                    textDecoration: "none",
                    fontSize: "24px",
                    marginBottom: "20px",
                  }}
                >
                  Logout
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => navigate("/login")}
                  sx={{ color: "green" }}
                  style={{
                    textDecoration: "none",
                    fontSize: "24px",
                    marginBottom: "20px",
                  }}
                >
                  Login
                </MenuItem>
              )}
            </Drawer>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
            }}
          ></Box>
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            {routes.map(
              (route) =>
                route.showInHeader && (
                  <Link
                    to={route.path}
                    key={route.title}
                    style={{ textDecoration: "none", color: colors.text }}
                  >
                    {!route.icon && (
                      <Button
                        variant={
                          location.pathname === route.path
                            ? "contained"
                            : "text"
                        }
                        color={
                          location.pathname === route.path
                            ? "dark"
                            : "secondary"
                        }
                        sx={{
                          border:
                            location.pathname === route.path
                              ? "2px solid black"
                              : "0",
                          borderRadius: "8px",
                        }}
                      >
                        {t(route.title)}
                      </Button>
                    )}
                  </Link>
                )
            )}
          </Box>

          <Menu
            PaperProps={{
              sx: {
                backgroundColor: colors.secondary,
                width: "170px",
                display: { xs: "none", md: "flex" },
              },
            }}
            onClose={handleProfileClose}
            onClick={handleProfileClose}
            disableAutoFocusItem
            disableScrollLock
            anchorEl={anchorProfileMenu}
            open={profileOpen}
          >
            <MenuItem
              id="en"
              style={{ minWidth: "100%", height: "30px" }}
              onClick={() => navigate("/history")}
            >
              {t("header.history")}
            </MenuItem>
            {userState.authenticated ? (
              <MenuItem onClick={handleLogout} sx={{ color: "red" }}>
                Logout
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => navigate("/login")}
                sx={{ color: "green" }}
              >
                Login
              </MenuItem>
            )}
          </Menu>
          <Button
            variant="text"
            color="secondary"
            onClick={handleProfileClick}
            sx={{ height: "30px", display: { xs: "none", md: "flex" } }}
          >
            {t("my.profile")}
            <KeyboardArrowDownIcon />
          </Button>

          <Button
            variant="text"
            color="secondary"
            onClick={handleLanguageClick}
            sx={{ height: "30px" }}
          >
            {showFlagDependingOnLanguage()}
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            PaperProps={{
              sx: { backgroundColor: colors.secondary, width: "170px" },
            }}
            onClose={handleLanguageClose}
            onClick={handleLanguageClose}
            disableAutoFocusItem
            disableScrollLock
            anchorEl={anchorLanguageMenu}
            open={languageOpen}
          >
            <MenuItem
              id="ro"
              style={{ minWidth: "100%", height: "30px" }}
              onClick={() => handleChangeLanguage("ro")}
            >
              <Flag code="RO" height="20" style={{ marginRight: "10px" }} />
              {t("header.language.ro")}
            </MenuItem>
            <MenuItem
              id="en"
              style={{ minWidth: "100%", height: "30px" }}
              onClick={() => handleChangeLanguage("en")}
            >
              <Flag code="GB" height="15" style={{ marginRight: "10px" }} />
              {t("header.language.en")}
            </MenuItem>
          </Menu>
          <Box sx={{ display: "flex", paddingRight: "0px !important" }}>
            {routes.map(
              (route) =>
                route.showInHeader && (
                  <Link
                    to={route.path}
                    key={route.title}
                    style={{
                      textDecoration: "none",
                      color: colors.text,
                    }}
                  >
                    {route.icon && (
                      <Button
                        sx={{
                          background: colors.customYellow,
                          color: "black",
                          height: "30px",
                          marginRight: "10px",
                        }}
                      >
                        <ShoppingCartIcon />
                        <Typography>
                          ({stickerState.stickers?.length})
                        </Typography>
                      </Button>
                    )}
                  </Link>
                )
            )}
          </Box>
          <Box sx={{ display: "flex", paddingRight: "15px !important" }}>
            <Typography fontSize="14px">
              {parseInt(currentPrice)} RON
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
