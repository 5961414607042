import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../..";

export const InfoBox = (props) => {
  return (
    <Box
      height="100%"
      padding="20px"
      sx={{
        backgroundColor: colors.secondary,
        border: "3px solid",
        borderColor: colors.customYellow,
        borderRadius: "16px",
      }}
    >
      <Grid item container xs={12} marginBottom="10px">
        {props.icon}
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
          fontSize="20"
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Typography>{props.description}</Typography>
      </Grid>
    </Box>
  );
};
