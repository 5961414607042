import React, { useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@mui/material";
import { colors } from "../../..";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { signup } from "../util/APIUtils";
import { useDispatch } from "react-redux";
import {
  displayToastError,
  displayToastSuccess,
} from "../../../utils/ErrorBoudary/errorBoudarySlice";

export const SignUpNew = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const signUpRequest = Object.assign({}, { name, email, password });

    signup(signUpRequest)
      .then((response) => {
        if (response.status === 200) {
          navigate("/login");
          dispatch(displayToastSuccess("Succesfully registered"));
        } else {
          dispatch(displayToastError(response.detail));
        }
      })
      .catch((error) => dispatch(displayToastError("Email already in use")));
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: `calc(100vh - 110px)` }}
    >
      <Paper
        sx={{
          width: { xs: "100%", md: "30vw" },
          padding: "12px",
          border: "6px solid",
          borderColor: colors.customYellow,
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginBottom="12px"
        >
          <Typography variant="h4"> {i18next.t("register.button")}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              name="name"
              placeholder={i18next.t("name")}
              fullWidth
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value.trim())}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              placeholder={i18next.t("password")}
              fullWidth
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </Grid>
          {password !== confirmPassword && confirmPassword.length > 0 && (
            <Grid item xs={12}>
              <Typography sx={{ color: "red" }}>
                {i18next.t("mismatch.password")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              name="confirmPassword"
              placeholder={i18next.t("confirm.password")}
              fullWidth
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} marginY="12px" justifyContent="center">
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            color="dark"
            disabled={password !== confirmPassword || password.length === 0}
            sx={{ border: "1px solid black" }}
          >
            {i18next.t("register.button")}
          </Button>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <span>
            {i18next.t("already.have.account")}{" "}
            <Link style={{ color: "black", fontWeight: "bold" }} to="/login">
              {i18next.t("login.here")}
            </Link>
          </span>
        </Grid>
      </Paper>
    </Grid>
  );
};
