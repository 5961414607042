import {
  Button,
  Card,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserAddressItem } from "./UserAddressItem";
import { NewAddressModal } from "./NewAddressModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddressesState,
  selectValidationState,
} from "../userAddressesSlice";
import {
  changeAddressValue,
  changeBillingValue,
  selectSelectedAddress,
} from "../../Sticker/createStickerSlice";
import { colors } from "../../..";

export const UserAddreses = (props) => {
  const { sameBillingAddress, setSameBillingAddress } = props;
  const { t } = useTranslation();
  const [openNewAddressModal, setOpenNewAddressModal] = useState(false);
  const addresses = useSelector(selectAddressesState);
  const selectedAddress = useSelector(selectSelectedAddress);
  const validation = useSelector(selectValidationState);
  const dispatch = useDispatch();

  const handleSelectSameBillingAddress = (value) => {
    setSameBillingAddress(value);
    if (value) {
      dispatch(changeBillingValue(selectedAddress));
    } else {
      dispatch(changeBillingValue({}));
    }
  };

  return (
    <Card
      style={{
        padding: "12px",
        borderRadius: "12px",
        marginTop: "12px",
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <NewAddressModal
        open={openNewAddressModal}
        handleClose={() => setOpenNewAddressModal(false)}
      />
      <Grid item container xs={12} spacing={3}>
        <Grid item container xs={12}>
          <Typography>{`3. ${t("user.addresses")}`}</Typography>
        </Grid>
        <Grid item container xs={12}>
          {addresses?.map((address, index) => (
            <UserAddressItem
              onSelect={() => dispatch(changeAddressValue(address))}
              key={index}
              name={`${address.name} ${address.lastName}`}
              address={`${address.street || "-"}, ${address.streetNo || "-"}, ${
                address.city || "-"
              }, ${address.county || "-"}`}
              billing={address.billing}
              userType={address.userType}
              address_id={address.id_user_info}
            />
          ))}
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            color="dark"
            onClick={() => setOpenNewAddressModal(true)}
          >
            {t("new.address")}
          </Button>
        </Grid>
        {validation.address && (
          <Grid item container xs={12} justifyContent="flex-start">
            <Typography>{t("please.select.address")}</Typography>
          </Grid>
        )}
        {selectedAddress && Object.keys(selectedAddress).length !== 0 && (
          <Grid item container xs={12} justifyContent="flex-start">
            <FormControlLabel
              control={
                <Checkbox
                  color="dark"
                  sx={{ color: colors.customYellow }}
                  checked={sameBillingAddress}
                  onChange={(event) =>
                    handleSelectSameBillingAddress(event.target.checked)
                  }
                />
              }
              label={t("same.billing.address")}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
