import { Backdrop, CircularProgress } from "@mui/material";

export const LoadingBackdrop = (props) => {
  const { isLoading } = props;
  return (
    <div>
      <Backdrop sx={{ zIndex: 5, color: "#fff" }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
