import React, { useEffect } from "react";
import { InputLabel, MenuItem, Box, FormControl } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import MuiSelect from "@mui/material/Select";
import { MATERIAL_TYPES_WITH_PVC } from "../../Auth/constants";

export const WrappingMenu = (props) => {
  const { setWrapping, wrapping, width, height, hasFolio, material } = props;
  const { t } = useTranslation();

  const Select = withStyles((theme) => ({
    select: {
      whiteSpace: "normal !important",
    },
  }))(MuiSelect);

  useEffect(() => {
    const showWrapping =
      (parseFloat(width) < 23 && parseFloat(height) < 33) ||
      (parseFloat(width) < 33 && parseFloat(height) < 23);

    if (
      !showWrapping &&
      wrapping !== "ResistantGlossy" &&
      wrapping !== "ResistantMatte"
    ) {
      setWrapping("None");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height]);

  //arata varianta infoliere doar daca eticheta nu depaseste 23x33
  const showWrapping =
    (parseFloat(width) < 23 && parseFloat(height) < 33) ||
    (parseFloat(width) < 33 && parseFloat(height) < 23);

  const hidden = MATERIAL_TYPES_WITH_PVC.includes(material) || !showWrapping;

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("wrapping")}</InputLabel>
        <Select
          defaultValue="None"
          disableAutoFocusItem
          value={wrapping}
          autoWidth
          disableScrollLock
          label={t("wrapping")}
          disabled={hasFolio}
          sx={{ fontSize: "12px", height: "60px" }}
          onChange={(event) => {
            setWrapping(event.target.value);
          }}
        >
          <MenuItem
            id="None"
            value="None"
            style={{ minWidth: "100%", backgroundColor: "white" }}
          >
            {t("NoneWrapping")}
          </MenuItem>

          {!hidden && (
            <MenuItem id="mat" value="Matte" style={{ minWidth: "100%" }}>
              {t("MatteWrapping")}
            </MenuItem>
          )}
          {!hidden && (
            <MenuItem id="lucios" style={{ minWidth: "100%" }} value="Glossy">
              {t("GlossyWrapping")}
            </MenuItem>
          )}
          {!hidden && (
            <MenuItem
              id="softTouch"
              style={{ minWidth: "100%" }}
              value="SoftTouch"
            >
              {t("SoftTouchWrapping")}
            </MenuItem>
          )}

          <MenuItem
            id="resistant_foil_glossy"
            style={{ minWidth: "100%" }}
            value="ResistantGlossy"
          >
            {t("ResistantGlossyWrapping")}
          </MenuItem>

          <MenuItem
            id="resistant_foil_matte"
            style={{ minWidth: "100%" }}
            value="ResistantMatte"
          >
            {t("ResistantMatteWrapping")}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
