import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { colors } from "../../..";
import { useTranslation } from "react-i18next";

export const PaymentMethodForm = (props) => {
  const { acceptCardPayment, setAcceptCardPayment } = props;
  const { t } = useTranslation();
  return (
    <Card
      style={{
        marginTop: "12px",
        padding: "12px",
        borderRadius: "12px",
        height: "auto",
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <Grid item container xs={12}>
        <Grid item container xs={12}>
          <Typography>{`2. ${t("payment.type")}`}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="dark"
                sx={{ color: colors.customYellow }}
                checked={acceptCardPayment}
                onChange={(event) => setAcceptCardPayment(event.target.checked)}
              />
            }
            label={
              <Typography
                style={{ color: "black" }}
                onClick={() => setAcceptCardPayment(true)}
              >
                {t("cardPayment")}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Card>
  );
};
