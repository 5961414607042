import {
  MATERIAL_TYPES_WITH_PVC,
  MAX_HEIGHT_NORMAL_MATERIAL,
  MAX_HEIGHT_PVC_MATERIAL,
  MAX_WIDTH_NORMAL_MATERIAL,
  MAX_WIDTH_PVC_MATERIAL,
  TWO_MM_IN_PIXELS,
} from "../../Auth/constants";

export const createSvgElement = (
  xTimes,
  yTimes,
  objects,
  widthPrint,
  heightPrint,
  isDieCut
) => {
  var svgElem = document.createElementNS("http://www.w3.org/2000/svg", "svg");

  svgElem.setAttributeNS(null, "viewBox", "0 0 " + 3000 + " " + 3000);
  svgElem.setAttribute("width", 3000);
  svgElem.setAttribute("height", 3000);

  svgElem.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svgElem.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
  svgElem.setAttribute("version", "1.1");
  svgElem.setAttribute("xml:space", "preserve");

  var symbol = document.createElement("symbol");
  symbol.setAttribute("id", "symbol");

  var doc = new DOMParser().parseFromString(
    `<svg xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> ${objects.toSVG()} </svg>`,
    "image/svg+xml"
  );

  symbol.append(doc.documentElement.childNodes[1]);
  svgElem.appendChild(symbol);

  const multiplicationVariableDieCut = isDieCut ? 4 : 2;
  // for (let y = 1; y <= yTimes; y++) {
  //   for (let x = 1; x <= xTimes; x++) {
  var use = document.createElement("use");
  use.setAttribute("xlink:href", "#symbol");
  use.setAttribute("x", 1); //add distance between stickers taking into consideration the new blid
  use.setAttribute("y", 1);
  svgElem.appendChild(use);
  //   }
  // }
  return svgElem;
};

export const computeNumberOfStickersOnSheetOfPaper = (
  isDieCut,
  widthPrint,
  heightPrint,
  material
) => {
  const multiplicationVariableDieCut = 0.6;

  const pageWidth = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_WIDTH_PVC_MATERIAL
    : MAX_WIDTH_NORMAL_MATERIAL;
  const pageHeight = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_HEIGHT_PVC_MATERIAL
    : MAX_HEIGHT_NORMAL_MATERIAL;
  const widthInCm = parseFloat(
    parseFloat(widthPrint) + parseFloat(multiplicationVariableDieCut)
  ).toFixed(2);
  const heightInCm = parseFloat(
    parseFloat(heightPrint) + parseFloat(multiplicationVariableDieCut)
  ).toFixed(2);

  let xTimes = Math.floor(pageWidth / widthInCm); //autocolant hartie-safezone: 45.9 x 31.1
  let yTimes = Math.floor(pageHeight / heightInCm); //autocolant pvc-safezone 42 x 30.1
  if (xTimes === 0) xTimes = 1;
  if (yTimes === 0) yTimes = 1;
  return xTimes * yTimes;
};

export const computeMaxWidthHeight = (rectRatio, material) => {
  let pageWidth = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_WIDTH_PVC_MATERIAL
    : MAX_WIDTH_NORMAL_MATERIAL;
  let pageHeight = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_HEIGHT_PVC_MATERIAL
    : MAX_HEIGHT_NORMAL_MATERIAL;
  let maxHeight = 0;
  let maxWidth = 0;

  pageHeight = pageHeight - 0.6;
  pageWidth = pageWidth - 0.6;
  if (rectRatio > 1) {
    if (pageHeight * rectRatio < pageWidth) {
      maxWidth = (pageHeight * rectRatio).toFixed(2);
      maxHeight = pageHeight;
    } else {
      maxWidth = pageWidth.toFixed(2);
      maxHeight = (pageWidth * (1 / rectRatio)).toFixed(2);
    }
  } else {
    if (pageWidth * rectRatio < pageHeight) {
      maxWidth = (pageHeight * rectRatio).toFixed(2);
      maxHeight = pageHeight.toFixed(2);
    } else {
      maxHeight = pageHeight.toFixed(2);
      maxWidth = (pageHeight * rectRatio).toFixed(2);
    }
  }
  maxWidth = parseFloat(maxWidth);
  maxHeight = parseFloat(maxHeight);
  return { maxHeight, maxWidth };
};
