import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeQuantityValue,
  removeSticker,
  selectLoadingState,
  selectStickerData,
  selectStickerForAttachement,
} from "../Sticker/createStickerSlice";
import { StickerCard } from "./components/StickerCard";
import { useTranslation } from "react-i18next";
import { LoadingBackdrop } from "../Components/LoadingBackdrop";
import { OrderReceiptComponent } from "./components/OrderReceiptComponent";
import { ImageModal } from "../OrderHistory/components/ImageModal";
import { ACCESS_TOKEN } from "../Auth/constants";
import { useNavigate } from "react-router-dom";
import { changeTotalPriceValue } from "../Pricing/pricingSlice";
import { colors } from "../../index";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Helmet } from "react-helmet";

export const Cart = (props) => {
  const stickerState = useSelector(selectStickerData);
  const stickerAttachements = useSelector(selectStickerForAttachement);
  const loadingState = useSelector(selectLoadingState);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalImage, setModalImage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      navigate("/login", { replace: true, state: { route: "/paymentForm" } });
    } else {
      navigate("/paymentForm");
    }
  };

  const handleChangeQuantity = (index, quantity) => {
    dispatch(changeQuantityValue({ index: index, quantity: quantity }));
  };

  const handleRemoveSticker = (index) => {
    dispatch(
      changeTotalPriceValue(-parseInt(stickerState.stickers[index].price))
    );
    dispatch(removeSticker(index));
  };

  return (
    <Grid container sx={{ minHeight: `calc(100vh - 110px)` }}>
      <Helmet>
        <title>PrintWorks Cos Cumparaturi</title>
        <meta
          name="description"
          content="Bine ati venit pe pagina noastra de cumparaturi. Aici puteti avea o privire detaliata asupra stickerelor configurate de dumneavostra."
        />
        <meta
          name="title"
          content="PrintWorks: Cosul tau de stickere personalizate! Finalizeaza-ti comanda si esti cu un pas mai aproape de stickerele tale"
        />
      </Helmet>
      <LoadingBackdrop isLoading={loadingState.saveToDrive} />

      <ImageModal
        open={openModal}
        image={modalImage}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container alignContent="start" spacing={3} justifyContent="center">
        <Grid
          item
          container
          xs={12}
          md={10}
          marginTop="12px"
          alignItems="center"
        >
          <ShoppingCartIcon
            style={{ color: colors.customYellow, fontSize: "40px" }}
          />
          <Typography
            fontSize="35px"
            padding="20px"
            color={colors.customYellow}
          >
            {t("your.order")}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={10.7}
          spacing={3}
          sx={{ paddingLeft: "0px !important", marginLeft: "0px !important" }}
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            sx={{ paddingLeft: { md: "0px !important" } }}
            justifyContent="center"
          >
            {stickerState?.stickers?.length > 0 && (
              <Grid item container spacing={2} xs={12} justifyContent="center">
                {stickerState?.stickers?.map((sticker, index) => (
                  <StickerCard
                    width={sticker.width}
                    quantity={sticker.quantity}
                    material={sticker.material}
                    price={sticker.price}
                    height={sticker.height}
                    image={sticker.image}
                    wrapping={sticker.wrapping}
                    isDieCut={sticker?.isDieCut}
                    handleOpenModal={() => setOpenModal(true)}
                    handleSetModalImage={(image) => setModalImage(image)}
                    index={index}
                    key={index}
                    handleRemoveSticker={(index) => handleRemoveSticker(index)}
                    handleChangeQuantity={(index, quantiy) =>
                      handleChangeQuantity(index, quantiy)
                    }
                  />
                ))}
              </Grid>
            )}
          </Grid>
          {stickerState?.stickers?.length === 0 && (
            <Grid item container xs={12} justifyContent="center">
              <Typography variant="h5" color={colors.customYellow}>
                {t("no.products.yet")}
              </Typography>
            </Grid>
          )}
          {stickerState?.stickers?.length > 0 && (
            <Grid item container xs={12} md={4} justifyContent="center">
              <OrderReceiptComponent
                stickers={stickerState?.stickers}
                handleClick={() => handleClick()}
                disabledButton={stickerAttachements?.length === 0}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
