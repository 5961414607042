import { fabric } from "fabric";
import {
  computeMaxWidthHeight,
  createSvgElement,
} from "./shapeProcessHelperFunctions";
import {
  MATERIAL_TYPES_WITH_PVC,
  MAX_WIDTH_NORMAL_MATERIAL,
  MAX_WIDTH_PVC_MATERIAL,
  TWO_MM_IN_PIXELS,
  MAX_HEIGHT_PVC_MATERIAL,
  MAX_HEIGHT_NORMAL_MATERIAL,
} from "../../Auth/constants";

var shadow = new fabric.Shadow({
  color: "black",
  blur: 10,
  offsetX: 7,
  offsetY: 7,
});

let circle = new fabric.Circle({
  fill: "white",
  shadow: shadow,
  absolutePositioned: true,
});

let rectangle = new fabric.Rect({
  fill: "white",
  centerScaling: true,
  shadow: shadow,
  absolutePositioned: true,
});

let rectangleChrome = new fabric.Rect({
  fill: "#FFCE44",
  centerScaling: true,
  opacity: "0.5",
  absolutePositioned: true,
});
let circleChrome = new fabric.Circle({
  fill: "#FFCE44",
  opacity: "0.5",
  absolutePositioned: true,
});

let canvas;
let canvas1;
let canvas2;
let rectRatio = 0;
let imgWidth = 0;
let imgHeight = 0;
let image;
let folioImage;

fabric.Image.prototype.getSvgSrc = function () {
  return this.toDataURLforSVG();
};

fabric.Image.prototype.toDataURLforSVG = function (options) {
  var el = fabric.util.createCanvasElement();

  el.width = this._element.naturalWidth || this._element.width;
  el.height = this._element.naturalHeight || this._element.height;
  el.getContext("2d").drawImage(this._element, 0, 0);

  var data = el.toDataURL(options);
  return data;
};

export const uploadImageToShape = (
  imageToUpload,
  setRectangularRatio,
  setWidth,
  setHeight,
  isRectangle,
  setMaxHeightPrint,
  setMaxWidthPrint,
  material
) => {
  canvas = new fabric.Canvas("canvas");

  fabric.Image.fromURL(imageToUpload, function (img) {
    image = img;
    var hRatio = 300 / image.width;
    var vRatio = 300 / image.height;

    rectRatio = image.width / image.height;

    var ratio = Math.min(hRatio, vRatio);
    image.set({ scaleX: ratio, scaleY: ratio });
    canvas.add(image);
    canvas.centerObject(image);
    drawRectangle();
    imgWidth = image.width * ratio;
    imgHeight = image.height * ratio;

    resizeCanvasShape(320, setWidth, setHeight, isRectangle);
    setRectangularRatio(rectRatio);

    const maxValues = computeMaxWidthHeight(rectRatio, material);
    setMaxHeightPrint(maxValues.maxHeight);
    setMaxWidthPrint(maxValues.maxWidth);
  });
  canvas.requestRenderAll();
};

export const uploadFolioGraphics = (imageToUpload, isRectangle) => {
  fabric.Image.fromURL(imageToUpload, function (img) {
    folioImage = img;
    var hRatio = 300 / folioImage.width;
    var vRatio = 300 / folioImage.height;

    var ratio = Math.min(hRatio, vRatio);
    folioImage.set({ scaleX: ratio, scaleY: ratio });
    canvas.add(folioImage);

    var filter = new fabric.Image.filters.BlendColor({
      color: "#D4AF37",
      mode: "tint",
    });
    folioImage.filters.push(filter);
    folioImage.applyFilters();
    canvas.renderAll();
    canvas.centerObject(folioImage);
    canvas.set("preserveObjectStacking", true);
    if (isRectangle) {
      folioImage && folioImage.set("clipPath", circle);
    } else {
      folioImage && folioImage.set("clipPath", rectangle);
    }
  });
  canvas.requestRenderAll();
};

export const removeFolioGraphics = () => {
  canvas.remove(folioImage);
  canvas.requestRenderAll();
};

export const resizeCanvasShape = (radius, setWidth, setHeight, isRectangle) => {
  if (rectRatio === 1 || !isRectangle) {
    rectangle.set("width", radius);
    rectangle.set("height", radius);
    setWidth(radius);
    setHeight(radius);
    circle.set("radius", parseFloat(radius / 2));
  } else if (rectRatio > 1) {
    rectangle.set("width", imgWidth + (radius - imgHeight));
    rectangle.set("height", radius);

    setWidth(imgWidth + (radius - imgHeight));
    setHeight(radius);

    // rectRatio = (imgWidth + (radius - imgHeight)) / radius;
  } else {
    rectangle.set("width", radius);
    rectangle.set("height", imgHeight + (radius - imgWidth));

    setWidth(radius);
    setHeight(imgHeight + (radius - imgWidth));

    // rectRatio = radius / (imgHeight + (radius - imgWidth));
  }

  canvas.centerObject(circle);
  canvas.centerObject(rectangle);
  canvas.requestRenderAll();
};

export const drawRectangle = () => {
  canvas.remove(circle);
  canvas.remove(rectangle);
  rectangle.selectable = false;
  rectangle.set("rx", 0);
  rectangle.set("ry", 0);
  canvas.add(rectangle);
  canvas.centerObject(rectangle);
  image.set("clipPath", rectangle);
  folioImage && folioImage.set("clipPath", rectangle);
  canvas.sendToBack(rectangle);
  canvas.requestRenderAll();
};

export const drawRoundedRectangle = () => {
  canvas.remove(circle);
  canvas.remove(rectangle);
  rectangle.selectable = false;
  rectangle.set("rx", 20);
  rectangle.set("ry", 20);
  canvas.add(rectangle);
  canvas.centerObject(rectangle);
  image.set("clipPath", rectangle);
  folioImage && folioImage.set("clipPath", rectangle);

  canvas.sendToBack(rectangle);
  canvas.requestRenderAll();
};

export const drawCircle = () => {
  canvas.remove(rectangle);
  rectangle.selectable = false;
  circle.selectable = false;

  canvas.add(circle);
  canvas.centerObject(circle);

  image.set("clipPath", circle);
  folioImage && folioImage.set("clipPath", circle);

  canvas.sendToBack(circle);
  canvas.requestRenderAll();
};

// export const drawChromeEffect = (isRectangle, isRounded, isChrome) => {
//   canvas.remove(circleChrome);
//   canvas.remove(rectangleChrome);
//   if (isChrome) {
//     rectangleChrome.set("fill", "#D8DBDE");
//     circleChrome.set("fill", "#D8DBDE");
//   } else {
//     rectangleChrome.set("fill", "#FFCE44");
//     circleChrome.set("fill", "#FFCE44");
//   }
//   if (isRectangle) {
//     if (isRounded) {
//       rectangleChrome.set("rx", 20);
//       rectangleChrome.set("ry", 20);
//     } else {
//       rectangleChrome.set("rx", 0);
//       rectangleChrome.set("ry", 0);
//     }
//     rectangleChrome.selectable = false;
//     rectangleChrome.set("width", rectangle.getScaledWidth());
//     rectangleChrome.set("height", rectangle.getScaledHeight());
//     canvas.add(rectangleChrome);
//     canvas.centerObject(rectangleChrome);
//   } else {
//     circleChrome.selectable = false;
//     circleChrome.set("radius", circle.getRadiusY());
//     canvas.add(circleChrome);
//     canvas.centerObject(circleChrome);
//   }
//   canvas.bringToFront(image);
//   canvas.requestRenderAll();
// };

export const changeColor = (color) => {
  circle.set("fill", color);
  rectangle.set("fill", color);
  canvas.requestRenderAll();
};

export const flipObjectVertical = () => {
  const objToFlip = canvas._objects.filter(function (obj) {
    return obj;
  });
  if (objToFlip) {
    objToFlip[1].set("flipY", !objToFlip[1].flipY);
    canvas.requestRenderAll();
  }
};

export const flipObjectHorizontal = () => {
  const objToFlip = canvas._objects.filter(function (obj) {
    return obj;
  });
  if (objToFlip) {
    objToFlip[1].set("flipX", !objToFlip[1].flipX);
    canvas.requestRenderAll();
  }
};

export const alignObjectVertical = () => {
  const objToFlip = canvas._objects.filter(function (obj) {
    return obj;
  });
  if (objToFlip) {
    objToFlip[1].centerV();
    canvas.requestRenderAll();
  }
};

export const alignObjectHorizontal = () => {
  const objToFlip = canvas._objects.filter(function (obj) {
    return obj;
  });
  if (objToFlip) {
    objToFlip[1].centerH();
    canvas.requestRenderAll();
  }
};

const removeTransparentBackground = (color) => {
  canvas.remove(rectangleChrome);
  canvas.remove(circleChrome);
  circle.set("fill", color);
  rectangle.set("fill", color);
  circle.set("opacity", 1);
  rectangle.set("opacity", 1);
  circle.set("strokeWidth", 0);
  rectangle.set("strokeWidth", 0);
};

export const setTransparentBackground = () => {
  canvas.remove(rectangleChrome);
  canvas.remove(circleChrome);
  circle.set("fill", "white");
  rectangle.set("fill", "white");
  circle.set("opacity", 0.2);
  rectangle.set("opacity", 0.2);
  circle.set("stroke", "#777");
  rectangle.set("stroke", "#777");
  circle.set("strokeWidth", 1);
  rectangle.set("strokeWidth", 1);

  canvas.bringToFront(image);
  canvas.requestRenderAll();
};

export const setPremiumBackground = (color) => {
  removeTransparentBackground(color);
  canvas.requestRenderAll();
};

export const handleCanvasDownload = (
  width,
  height,
  widthPrint,
  heightPrint,
  quantity,
  material,
  isRectangle,
  isTransparent,
  isRoundedCorners,
  handleAddStickerToCard,
  isDieCut,
  stickerPrice,
  noOnSheet,
  wrapping
) => {
  canvas1 = new fabric.Canvas("canvas1");
  canvas2 = new fabric.Canvas("canvas2");

  var objs = [];
  //get all the objects into an array
  objs = canvas._objects.filter(function (obj) {
    return obj;
  });

  circle.set("shadow", null);
  rectangle.set("shadow", null);

  if (isTransparent) {
    removeTransparentBackground();
  }

  //logica pentru cerc care trebuie facut pe poza dreptunghiulara
  if (!isRectangle) {
    if (width > height) {
      height = width;
    } else {
      width = height;
    }
    if (widthPrint > heightPrint) {
      heightPrint = widthPrint;
    } else {
      widthPrint = heightPrint;
    }
  }

  const pageWidth = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_WIDTH_PVC_MATERIAL
    : MAX_WIDTH_NORMAL_MATERIAL;
  const pageHeight = MATERIAL_TYPES_WITH_PVC.includes(material)
    ? MAX_HEIGHT_PVC_MATERIAL
    : MAX_HEIGHT_NORMAL_MATERIAL;

  const multiplicationVariableDieCut = isDieCut ? 3 : 1;
  //modificat pentru blid sa nu mai mareasca imaginea
  const scaleRationWidth = widthPrint / width; //resize shape for 2 mm blid
  const scaleRationHeight = heightPrint / height; //resize shape for 2 mm blid

  const widthInCm =
    ((widthPrint + multiplicationVariableDieCut * TWO_MM_IN_PIXELS) / 96) *
    2.54;
  const heightInCm =
    ((heightPrint + multiplicationVariableDieCut * TWO_MM_IN_PIXELS) / 96) *
    2.54;

  const xTimes1 = Math.floor(pageWidth / widthInCm);
  const yTimes1 = Math.floor(pageHeight / heightInCm);

  const xTimes2 = Math.floor(pageHeight / widthInCm);
  const yTimes2 = Math.floor(pageWidth / heightInCm);

  const xTimes = xTimes2 * yTimes2 > xTimes1 * yTimes1 ? xTimes2 : xTimes1;
  const yTimes = xTimes2 * yTimes2 > xTimes1 * yTimes1 ? yTimes2 : yTimes1;

  let x = 1;
  let y = 1;
  //group all the objects

  var alltogetherObj = new fabric.Group(objs, {
    top: y * (heightPrint + TWO_MM_IN_PIXELS), //add 2 pixels to distance
    left: x * (widthPrint + TWO_MM_IN_PIXELS), //add 2 pixels to distance
    originX: "center",
    originY: "center",
    scaleX: scaleRationWidth,
    scaleY: scaleRationHeight,
  });

  var circleBorder = new fabric.Circle({
    top: y * (heightPrint + TWO_MM_IN_PIXELS),
    left: x * (widthPrint + TWO_MM_IN_PIXELS),
    originX: "center",
    originY: "center",
    radius: heightPrint / 2,
    stroke: "cyan",
    fill: "transparent",
    strokeWidth: 1,
  });

  var rectangleBorder = new fabric.Rect({
    top: y * (heightPrint + TWO_MM_IN_PIXELS),
    left: x * (widthPrint + TWO_MM_IN_PIXELS),
    originX: "center",
    originY: "center",
    rx: isRoundedCorners ? 20 : 0,
    ry: isRoundedCorners ? 20 : 0,
    width: widthPrint,
    height: heightPrint,
    fill: "transparent",
    stroke: "cyan",
    strokeWidth: 1,
    centerScaling: true,
  });

  canvas1.add(alltogetherObj);
  alltogetherObj.setCoords();
  image.set("clipPath", null);
  folioImage && folioImage.set("clipPath", null);

  //create single object image
  var imageToSave = new fabric.Group(objs, {
    originX: "center",
    originY: "center",
    scaleX: scaleRationWidth,
    scaleY: scaleRationHeight,
  });

  const myImage = imageToSave
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");

  canvas1.requestRenderAll();

  canvas.requestRenderAll();
  var canv1objects = new fabric.ActiveSelection(canvas1.getObjects(), {});

  const svgElem1 = createSvgElement(
    xTimes,
    yTimes,
    canv1objects,
    widthPrint,
    heightPrint,
    isDieCut
  );

  if (isRectangle) {
    var rectangleOuterBorder = new fabric.Rect({
      top: y * (heightPrint + TWO_MM_IN_PIXELS),
      left: x * (widthPrint + TWO_MM_IN_PIXELS),
      originX: "center",
      originY: "center",
      rx: isRoundedCorners ? 20 : 0,
      ry: isRoundedCorners ? 20 : 0,
      width: widthPrint + 2 * TWO_MM_IN_PIXELS,
      height: heightPrint + 2 * TWO_MM_IN_PIXELS,
      fill: "transparent",
      stroke: "magenta",
      strokeWidth: 1,
      centerScaling: true,
    });

    canvas2.add(rectangleBorder);
    isDieCut && canvas2.add(rectangleOuterBorder);
    rectangleBorder.setCoords();
    isDieCut && rectangleOuterBorder.setCoords();
  } else {
    var circleOuterBorder = new fabric.Circle({
      top: y * (heightPrint + TWO_MM_IN_PIXELS),
      left: x * (widthPrint + TWO_MM_IN_PIXELS),
      originX: "center",
      originY: "center",
      radius: (heightPrint + 2 * TWO_MM_IN_PIXELS) / 2,
      stroke: "magenta",
      fill: "transparent",
      strokeWidth: 1,
    });
    canvas2.add(circleBorder);
    isDieCut && canvas2.add(circleOuterBorder);
    isDieCut && circleOuterBorder.setCoords();
    circleBorder.setCoords();
  }
  canvas2.requestRenderAll();

  var canv2objects = new fabric.ActiveSelection(canvas2.getObjects(), {});

  const svgElem2 = createSvgElement(
    xTimes,
    yTimes,
    canv2objects,
    widthPrint,
    heightPrint,
    isDieCut
  );

  //add object to redux state
  handleAddStickerToCard({
    image: myImage,
    width: (widthPrint / 96) * 2.54,
    height: (heightPrint / 96) * 2.54,
    quantity: quantity,
    material: material,
    price: stickerPrice,
    svgFile1: svgElem1.outerHTML,
    svgFile2: svgElem2.outerHTML,
    isDieCut: isDieCut,
    noOnSheet: noOnSheet,
    wrapping: wrapping,
  });
};

//clear canvases
export const resetCanvases = () => {
  canvas?.getObjects().length > 0 && canvas?.dispose();
  canvas1?.getObjects().length > 0 && canvas1?.dispose();
  canvas2?.getObjects().length > 0 && canvas2?.dispose();

  rectRatio = 0;
  imgWidth = 0;
  imgHeight = 0;
  circle = new fabric.Circle({
    fill: "white",
    shadow: shadow,
    absolutePositioned: true,
  });

  rectangle = new fabric.Rect({
    fill: "white",
    centerScaling: true,
    shadow: shadow,
    absolutePositioned: true,
  });

  rectangleChrome = new fabric.Rect({
    fill: "#FFCE44",
    centerScaling: true,
    opacity: "0.5",
    absolutePositioned: true,
  });

  circleChrome = new fabric.Circle({
    fill: "#FFCE44",
    opacity: "0.5",
    absolutePositioned: true,
  });
};
