import React from "react";
import { Modal } from "@mui/material";

export const ImageModal = (props) => {
  const { open, image, handleClose } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
      }}
    >
      <img
        src={image}
        alt="Stickerul configurat de tine din cosul de cumparaturi"
      />
    </Modal>
  );
};
