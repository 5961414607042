import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { colors } from "../../..";
import { useTranslation } from "react-i18next";

export const DeliveryForm = (props) => {
  const { deliveryMethod, setDeliveryMethod } = props;
  const { t } = useTranslation();
  return (
    <Card
      style={{
        marginTop: "12px",
        padding: "12px",
        borderRadius: "12px",
        height: "auto",
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <Grid item container xs={12}>
        <Grid item container xs={12}>
          <Typography>{`1. ${t("delivery.type")}`}</Typography>
        </Grid>
        <Grid item container paddingLeft="12px">
          <FormControl>
            <RadioGroup
              row
              value={deliveryMethod}
              onChange={(event) => setDeliveryMethod(event.target.value)}
              defaultValue={deliveryMethod}
              name="delivery-options"
            >
              <Grid
                item
                sx={{
                  background: colors.secondary,
                  borderRadius: "12px",
                  paddingX: "5px",
                  margin: "12px",
                }}
              >
                <FormControlLabel
                  value="curier"
                  control={<Radio color="dark" />}
                  label={t("curier.delivery")}
                />
              </Grid>
              <Grid
                item
                sx={{
                  background: colors.secondary,
                  borderRadius: "12px",
                  paddingX: "5px",
                  margin: "12px",
                }}
              >
                <FormControlLabel
                  value="pickup"
                  control={<Radio color="dark" />}
                  label={t("pick.up")}
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        {deliveryMethod !== "curier" && (
          <Grid item xs={12}>
            <Typography>Adresa de ridicare: Paris 70, Cluj- Napoca</Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
