import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {
    openMessage: false,
    feedbackType: "info",
    feedbackMessage: "",
  },
};

export const errorBoundaryFormSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setSnackbarStatus: (state, action) => ({
      ...state,
      openMessage: action.payload,
    }),
    displayToastError: (state, { payload }) => ({
      ...state,
      openMessage: true,
      feedbackType: "error",
      feedbackMessage: payload,
    }),
    displayToastSuccess: (state, { payload }) => ({
      ...state,
      openMessage: true,
      feedbackType: "success",
      feedbackMessage: payload,
    }),
  },
});

export const { setSnackbarStatus, displayToastError, displayToastSuccess } =
  errorBoundaryFormSlice.actions;

export const selectErrorData = (state) => state.error.formData;

export default errorBoundaryFormSlice.reducer;
