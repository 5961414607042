import React, { useState } from "react";
import { Grid, Paper, TextField, Button, Typography } from "@mui/material";
import { colors } from "../../..";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../api";

export const ResetEmail = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleSubmit = () => {
    resetPassword(email).then(()=>{
      navigate("/emailNotification");
     });
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: `calc(100vh - 110px)` }}
    >
      <Paper
        sx={{
          width: { xs: "100%", md: "30vw" },
          padding: "12px",
          border: "6px solid",
          borderColor: colors.customYellow,
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginBottom="12px"
        >
          <Typography variant="h4"> {i18next.t("reset.password")}</Typography>
        </Grid>
        <Grid item container xs={12} spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} marginY="12px" justifyContent="center">
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            color="dark"
            sx={{ border: "1px solid black" }}
          >
            {i18next.t("send.reset.button")}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};
