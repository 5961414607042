import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPriceList, getDiscountList } from "../../api";
import { displayToastError } from "../../utils/ErrorBoudary/errorBoudarySlice";

export const getStickerPrices = createAsyncThunk(
  "prices/getStickerPrices",
  async (data, thunkAPI) => {
    const response = await getPriceList();
    if (!response.error) {
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.detail));
      thunkAPI.rejectWithValue("Cannot get prices");
    }
  }
);

export const getDiscounts = createAsyncThunk(
  "prices/getDiscounts",
  async (data, thunkAPI) => {
    const response = await getDiscountList();

    if (!response.error) {
      return response;
    } else {
      thunkAPI.rejectWithValue("Cannot get discounts");
    }
  }
);

export const priceSliceReducer = createSlice({
  name: "prices",
  initialState: {
    formData: {
      totalPrice: 0,
      currentPrice: 0,
      stickerPrices: [],
      discountValue: 0,
      discountCode: "",
      discounts: [],
    },
  },
  reducers: {
    changeTotalPriceValue: (state, action) => {
      state.formData.totalPrice += action.payload;
    },
    resetTotalPrice: (state, action) => {
      state.formData.totalPrice = 0;
      state.formData.discountValue = 0;
    },
    changeCurrentPriceValue: (state, action) => {
      state.formData.currentPrice = action.payload;
    },
    changeDiscountValue: (state, action) => {
      state.formData.discountValue = action.payload;
    },
    changeDiscounCode: (state, action) => {
      state.formData.discountCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStickerPrices.fulfilled, (state, action) => {
      state.formData.stickerPrices = action.payload;
    });
    builder.addCase(getStickerPrices.pending, (state, action) => {});
    builder.addCase(getStickerPrices.rejected, (state, action) => {});

    builder.addCase(getDiscounts.fulfilled, (state, action) => {
      state.formData.discounts = action.payload;
    });
    builder.addCase(getDiscounts.pending, (state, action) => {});
    builder.addCase(getDiscounts.rejected, (state, action) => {});
  },
});

export const {
  changeTotalPriceValue,
  changeCurrentPriceValue,
  resetTotalPrice,
  changeDiscounCode,
  changeDiscountValue,
} = priceSliceReducer.actions;
export const selectPricingList = (state) =>
  state.pricing.formData.stickerPrices;
export const selectTotalPrice = (state) => state.pricing.formData.totalPrice;
export const selectCurrentPrice = (state) =>
  state.pricing.formData.currentPrice;
export const selectDiscountValue = (state) =>
  state.pricing.formData.discountValue;
export const selectDiscountCode = (state) =>
  state.pricing.formData.discountCode;
export const selectDiscounts = (state) => state.pricing.formData.discounts;

export default priceSliceReducer.reducer;
