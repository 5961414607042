export const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:8080"
    : "https://api.printworks.ro/printworks";
export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/oauth2/redirect"
    : "https://printworks.ro/oauth2/redirect";

export const GOOGLE_AUTH_URL =
  API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  API_BASE_URL +
  "/oauth2/authorize/facebook?redirect_uri=" +
  OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
  API_BASE_URL + "/oauth2/authorize/github?redirect_uri=" + OAUTH2_REDIRECT_URI;

export const TWO_MM_IN_PIXELS = 7.559055118110237;
export const STANDART_STICKER_DIMENSION_CM2 = 16;
export const MAX_WIDTH_NORMAL_MATERIAL = 45.9;
export const MAX_HEIGHT_NORMAL_MATERIAL = 31.1;
export const MAX_WIDTH_PVC_MATERIAL = 42.0;
export const MAX_HEIGHT_PVC_MATERIAL = 30.1;
export const DIE_CUT_PRICE = 3;
export const PROFIT = 2;

export const MATERIAL_TYPES_WITH_PVC = [
  "matte_pvc",
  "glossy_pvc",
  "semitransparent",
  "transparent",
];
