import { Slide, Snackbar, Alert, ClickAwayListener } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { setSnackbarStatus } from "./errorBoudarySlice";

const ErrorBoundary = ({ openMessage, feedbackType, feedbackMessage }) => {
  const dispatch = useDispatch();
  const handleClose = (event, reason) =>
    reason !== "clickaway" && dispatch(setSnackbarStatus(false));
  const { t } = useTranslation();
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClose}
    >
      <Snackbar
        open={openMessage}
        onClose={handleClose}
        autoHideDuration={5000}
        sx={{ display: openMessage ? "block" : "none" }}
        hidden={!openMessage}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
      >
        <Alert severity={feedbackType || "info"}>{t(feedbackMessage)}</Alert>
      </Snackbar>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => ({
  openMessage: state.error.openMessage,
  feedbackType: state.error.feedbackType,
  feedbackMessage: state.error.feedbackMessage,
});

const connector = connect(mapStateToProps);

export default connector(ErrorBoundary);
