import { Button, Card, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserAddressItem } from "./UserAddressItem";
import { useState } from "react";
import { NewAddressModal } from "./NewAddressModal";
import { useDispatch, useSelector } from "react-redux";
import { selectBillingState } from "../userAddressesSlice";
import { changeBillingValue } from "../../Sticker/createStickerSlice";

export const BillingForm = (props) => {
  const { t } = useTranslation();
  const [openNewBillingModal, setOpenNewBillingModal] = useState(false);
  const billingAddresses = useSelector(selectBillingState);
  const dispatch = useDispatch();
  return (
    <Card
      style={{
        padding: "12px",
        borderRadius: "12px",
        marginTop: "12px",
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <NewAddressModal
        billing
        open={openNewBillingModal}
        handleClose={() => setOpenNewBillingModal(false)}
      />
      <Grid item container xs={12} spacing={3}>
        <Grid item container xs={12}>
          <Typography>{`4. ${t("billing.addresses")}`}</Typography>
        </Grid>

        <Grid item container xs={12}>
          {billingAddresses?.map((billing, index) => (
            <UserAddressItem
              onSelect={() => dispatch(changeBillingValue(billing))}
              key={index}
              name={`${billing.name} ${billing.lastName}`}
              address={`${billing.street || "-"}, ${billing.streetNo || "-"}, ${
                billing.city || "-"
              }, ${billing.county || "-"}`}
              company={`${billing.companyName || "-"}, ${
                billing.vatCode || "-"
              }, ${billing.bank || "-"}`}
              billing={billing.billing}
              userType={billing.userType}
              address_id={billing.id_user_info}
            />
          ))}
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            variant="contained"
            color="dark"
            onClick={() => setOpenNewBillingModal(true)}
          >
            {t("new.billing")}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
