import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveFileToDrive } from "../../api";
import {
  displayToastError,
  displayToastSuccess,
} from "../../utils/ErrorBoudary/errorBoudarySlice";

export const saveToDrive = createAsyncThunk(
  "stickers/saveToDrive",
  async (
    {
      stickers,
      totalPrice,
      userInformation,
      userBilling,
      hasCourier,
      discountCode,
    },
    thunkAPI
  ) => {
    const response = await saveFileToDrive(
      stickers,
      totalPrice,
      userInformation,
      userBilling,
      hasCourier,
      discountCode
    );
    if (response.errorText.length === 0) {
      thunkAPI.dispatch(displayToastSuccess("Send order succsesful"));
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.errorMessage));
      thunkAPI.rejectWithValue("Cannot send order");
    }
  }
);

export const stickerSliceReducer = createSlice({
  name: "stickers",
  initialState: {
    formData: {
      stickers: [],
      noOnSheet: 0,
      selectedAddress: {},
      selectedBillingAddress: {},
      loading: {
        saveToDrive: false,
        getStickerPrices: false,
      },
    },
  },
  reducers: {
    changeStickersValue: (state, action) => {
      state.formData.stickers.push(action.payload);
    },
    changeNoOnSheetValue: (state, action) => {
      state.formData.noOnSheet = action.payload;
    },
    changeAddressValue: (state, action) => {
      state.formData.selectedAddress = action.payload;
    },

    changeBillingValue: (state, action) => {
      state.formData.selectedBillingAddress = action.payload;
    },
    changeQuantityValue: (state, action) => {
      state.formData.stickers[action.payload.index].quantity =
        action.payload.quantity;
    },
    removeSticker: (state, action) => {
      state.formData.stickers.splice(action.payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveToDrive.fulfilled, (state, action) => {
      state.formData.loading.saveToDrive = false;
      state.formData.stickers = [];
    });
    builder.addCase(saveToDrive.pending, (state, action) => {
      state.formData.loading.saveToDrive = true;
    });
    builder.addCase(saveToDrive.rejected, (state, action) => {
      state.formData.loading.saveToDrive = false;
    });
  },
});

export const {
  changeStickersValue,
  changeQuantityValue,
  removeSticker,
  changeBillingValue,
  changeAddressValue,
  changeNoOnSheetValue,
} = stickerSliceReducer.actions;
export const selectStickerData = (state) => state.stickers.formData;
export const selectSelectedAddress = (state) =>
  state.stickers.formData.selectedAddress;
export const selectNoOnSheet = (state) => state.stickers.formData.noOnSheet;
export const selectBillingAddress = (state) =>
  state.stickers.formData.selectedBillingAddress;
export const selectLoadingState = (state) => state.stickers.formData.loading;

export const selectStickerForAttachement = (state) =>
  state.stickers.formData.stickers.map((sticker) => sticker.svgFile);

export default stickerSliceReducer.reducer;
