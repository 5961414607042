import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { colors } from "../../..";
import i18next from "i18next";

export const EmailNotification = (props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: `calc(100vh - 110px)` }}
    >
      <Paper
        sx={{
          width: { xs: "100%", md: "30vw" },
          padding: "12px",
          border: "6px solid",
          borderColor: colors.customYellow,
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginBottom="12px"
        >
          <Typography variant="h5"> {i18next.t("change.password.verify")}</Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};
