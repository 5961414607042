import React from "react";
import { Grid, Button, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../..";
import { useTranslation } from "react-i18next";
import { InfoBox } from "./components/InfoBox";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import LeftImage from "../../assets/background-img-left.svg";
import RightImage from "../../assets/background-img-right.svg";
import { Helmet } from "react-helmet";

export const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:1250px)");

  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - 110px)` }}
      justifyContent="center"
    >
      <Helmet>
        <title>PrintWorks Stickere</title>
        <meta
          name="description"
          content="Explorează creativitatea ta cu stickere personalizate! Site-ul nostru oferă un mod unic de a-ti configura stickerele pentru a adăuga un plus de originalitate și personalitate obiectelor tale preferate"
        />
        <meta
          name="title"
          content="Printworks: Configuratorul tau de stickere. Noi te ajutam sa iti realizezi sticker-ele dorite"
        />
      </Helmet>
      {matches && (
        <>
          <span>
            <img
              src={LeftImage}
              style={{
                height: `calc(100vh - 50px)`,
                top: "50px",
                left: "0",
                position: "absolute",
                aspectRatio: "",
              }}
              alt="Exemple de stickere configurate pe site-ul nsotru"
            />
          </span>
          <span>
            <img
              src={RightImage}
              style={{
                height: `calc(100vh - 50px)`,
                top: "50px",
                right: "0",
                position: "absolute",
              }}
              alt="Stickere configurate cu ajutorul printworks"
            />
          </span>
        </>
      )}

      <Grid
        item
        container
        xs={12}
        alignContent="center"
        sx={{ height: "100vh" }}
      >
        <Grid
          item
          container
          xs={12}
          justifyContent="space-evenly"
          alignContent="space-evenly"
          sx={{ height: "50vh" }}
        >
          <Grid item container justifyContent="center">
            <Typography
              textAlign="center"
              variant="h1"
              sx={{ textTransform: "uppercase" }}
              fontSize={matches ? "30px" : "15px"}
              color={colors.customYellow}
            >
              {t("awesomeSticker.homepage")}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Typography
              fontSize={matches ? "20px" : "15px"}
              textAlign="center"
              color={colors.secondary}
            >
              {t("uploadImage.homepage")}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Button
              variant="contained"
              sx={{ height: matches ? "50px" : "30px" }}
              color="dark"
              onClick={() => navigate("/sticker")}
            >
              {t("goToSticker.button")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
        sx={{
          height: "50px",
          marginTop: { xs: "0", md: "-50px" },
          backgroundColor: colors.customYellow,
        }}
      >
        <Typography
          fontWeight="bold"
          fontStyle="italic"
          fontSize="20px"
          textAlign="center"
        >
          "PrintWorks: Your Vision, Our Expertise!"
        </Typography>
      </Grid>
      <Grid
        item
        container
        marginTop="30px"
        sx={{ height: { xs: "50vh", md: "30vh" } }}
        justifyContent="space-evenly"
      >
        <Grid
          item
          container
          justifyContent="center"
          xs={11}
          md={3}
          sx={{ marginBottom: { xs: "10px", md: "0" } }}
        >
          <InfoBox
            title={t("shipping.to.your.location")}
            icon={<LocalShippingIcon />}
            description={t("description.shipping.to.your.location")}
          />
        </Grid>
        <Grid
          item
          container
          xs={11}
          md={3}
          justifyContent="center"
          sx={{ marginBottom: { xs: "10px", md: "0" } }}
        >
          <InfoBox
            title={t("design.yourself")}
            icon={<ModeEditIcon />}
            description={t("description.design.yourself")}
          />
        </Grid>
        <Grid item container xs={11} md={3} justifyContent="center">
          <InfoBox
            title={t("multiple.materials.to.choose.from")}
            icon={<DynamicFeedIcon />}
            description={t("description.multiple.materials.to.choose.from")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
