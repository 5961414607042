import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDiscounCode,
  changeDiscountValue,
  selectDiscountValue,
  selectDiscounts,
  selectTotalPrice,
} from "../../Pricing/pricingSlice";

const spanStyle = {
  margin: "0",
  // fontFamily: 'Open Sans MS,Open Sans,cursive',
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "1.5",
  color: "#000000",
};

export const OrderReceiptComponent = (props) => {
  const { handleClick, disabledButton } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deliveryCost, setDeliveryCost] = useState(15);
  const totalPrice = useSelector(selectTotalPrice);
  const discounts = useSelector(selectDiscounts);
  const [discountCode, setDiscountCode] = useState("");
  const [wrongDiscount, setWrongDiscount] = useState(false);
  const discountValue = useSelector(selectDiscountValue);
  //sa punem text pentru reducere de transport de 300 lei si de discount applied
  const verifyDiscountCode = () => {
    let valueOfDiscount;
    let found = false;
    discounts?.forEach((discount) => {
      if (discount.code.toUpperCase() === discountCode.toUpperCase()) {
        valueOfDiscount = discount.value;
        found = true;
      }
    });
    if (found) {
      dispatch(changeDiscountValue(valueOfDiscount));
      dispatch(changeDiscounCode(discountCode));
      setWrongDiscount(false);
    } else {
      dispatch(changeDiscountValue(0));
      dispatch(changeDiscounCode(""));
      setWrongDiscount(true);
    }
  };
  useEffect(() => {
    if (totalPrice > 300) {
      setDeliveryCost(0);
    } else {
      setDeliveryCost(15);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  useEffect(() => {
    dispatch(changeDiscountValue(0));
    dispatch(changeDiscounCode(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid item container xs={11}>
      <Grid
        item
        container
        xs={12}
        sx={{
          background: "white",
          borderRadius: "12px",
          padding: "25px",
          height: { xs: "auto", xl: "400px" },
          border: "5px solid #FFBF00",
        }}
      >
        <Grid
          item
          xs={12}
          style={{ borderBottom: "2px solid grey", padding: "10px" }}
        >
          <Typography fontStyle="bold" variant="h5">
            {t("orderSummary")}
          </Typography>
        </Grid>
        <Grid item container xs={12} style={{ padding: "10px 10px 0px 10px" }}>
          <Grid item xs={6}>
            <span style={spanStyle}>{t("product.costs")}:</span>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Typography>{`${totalPrice} RON`}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ borderBottom: "2px solid grey", padding: "10px" }}
        >
          <Grid item xs={6}>
            <span style={spanStyle}>{t("delivery.costs")}:</span>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Typography>{`${deliveryCost} RON`}</Typography>
          </Grid>
          {totalPrice < 300 && (
            <Grid item container xs={12}>
              <Typography
                sx={{ color: "red", fontWeight: "1rem", fontFamily: "None" }}
                fontSize="1 rem"
              >
                *{t("text.add.products")} {300 - totalPrice} RON{" "}
                {t("text.discount.transport")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} style={{ padding: "10px" }}>
          <Grid item xs={6}>
            <Typography variant="h5" fontStyle="bold">
              {t("total")}:
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            marginBottom="10px"
          >
            <Typography variant="h5" fontStyle="bold">
              {`${
                Math.ceil(
                  parseInt(totalPrice * ((100 - discountValue) / 100))
                ) + parseInt(deliveryCost)
              } RON`}
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            xs={8}
            justifyContent="flex-end"
          >
            <TextField
              onChange={(event) => {
                setDiscountCode(event.target.value);
                setWrongDiscount(false);
              }}
              placeholder="Cod de reducere"
              inputProps={{
                sx: {
                  height: "2px",
                },
              }}
            />
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={() => verifyDiscountCode()}
              variant="contained"
              color="dark"
              sx={{ border: "1px solid black", height: "34px" }}
            >
              Verifica
            </Button>
          </Grid>
          {discountValue !== 0 && (
            <Grid item container xs={12}>
              <Typography
                sx={{ fontWeight: "1rem", fontFamily: "None" }}
                fontSize="1 rem"
              >
                *{t("text.discount.added")} {discountValue}%
              </Typography>
            </Grid>
          )}
          {wrongDiscount && (
            <Grid item container xs={12}>
              <Typography
                sx={{ fontWeight: "1rem", fontFamily: "None", color: "red" }}
                fontSize="1 rem"
              >
                *{t("text.wrong.discount")}: {discountCode}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid item container xs={12} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={handleClick}
              color="dark"
              sx={{ marginTop: "12px", border: "1px solid black" }}
              disabled={disabledButton}
            >
              {t("order")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
