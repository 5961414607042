import React from "react";
import { ACCESS_TOKEN } from "../constants";
import { Navigate } from "react-router-dom";

export const OAuth2NavigateHandler = (props) => {
  const getUrlParameter = (name) => {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(window.location.href);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const token = getUrlParameter("token");
  const error = getUrlParameter("error");
  const redirectRoute = window.sessionStorage.getItem("redirectRoute");
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
    return (
      <Navigate
        to={{
          pathname: redirectRoute,
          state: { from: props.location },
        }}
      />
    );
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
          state: {
            from: props.location,
            error: error,
          },
        }}
      />
    );
  }
};

export default OAuth2NavigateHandler;
