import { Grid, Typography, TextField, Button } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { changeStickersValue } from "../../Sticker/createStickerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { colors } from "../../..";
import { displayToastSuccess } from "../../../utils/ErrorBoudary/errorBoudarySlice";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { computeOrderedHistoryPrice } from "../../Pricing/Pricing";
import { computeNumberOfStickersOnSheetOfPaper } from "../../Sticker/utils/shapeProcessHelperFunctions";
import {
  changeTotalPriceValue,
  getStickerPrices,
  selectPricingList,
} from "../../Pricing/pricingSlice";

export const OrderHistoryRowDetails = (props) => {
  const {
    image,
    height,
    width,
    quantity,
    material,
    handleImageClick,
    fileNameChild,
    fileNameParent,
    driveId,
    wrapping,
    isDieCut,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [quantityState, setQuantityState] = useState(quantity);
  const priceList = useSelector(selectPricingList);
  const noOnSheet = computeNumberOfStickersOnSheetOfPaper(
    isDieCut,
    width,
    height,
    material
  );

  const [currentPrice, setCurrentPrice] = useState(0);

  useEffect(() => {
    if (priceList.length === 0) {
      dispatch(getStickerPrices());
    }
    const price = computeOrderedHistoryPrice(
      quantityState,
      material,
      isDieCut,
      noOnSheet,
      wrapping,
      priceList
    );
    setCurrentPrice(price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityState, priceList]);
  const handleReorder = () => {
    dispatch(
      changeStickersValue({
        image: image,
        width: width,
        height: height,
        quantity: quantityState,
        material: material,
        price: currentPrice,
        noOnSheet: noOnSheet,
        isDieCut: isDieCut,
        driveFileNameChild: fileNameChild,
        driveFileNameParent: fileNameParent,
        driveId: driveId,
        wrapping: wrapping,
      })
    );
    dispatch(changeTotalPriceValue(currentPrice));

    dispatch(displayToastSuccess("Add to cart succesful"));
  };

  const spanStyle = {
    margin: "0",
    // fontFamily: 'Open Sans MS,Open Sans,cursive',
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5",
    color: "#000000",
  };

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        background: colors.secondary,
        borderRadius: "12px",
        marginBottom: "12px",
      }}
      alignItems="center"
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          background: colors.secondary,
          borderRadius: "12px",
          padding: "10px",
        }}
        alignItems="center"
      >
        <Grid item container xs={6} md={2} alignContent="center">
          <img
            src={image}
            onClick={() => handleImageClick(image)}
            alt="Unul dintre stickerele personalizate care au ajuns la tine"
            style={{ height: "100px", width: "100px" }}
          />
        </Grid>
        <Grid item container xs={6} md={4} direction="column">
          <Grid item container xs={12} justifyContent="center">
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("material")}:`}</span> &nbsp;
              <Typography>{t(`cart_${material}`)}</Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}> {`${t("height")}:`} </span> &nbsp;
              <Typography> {`${height.toFixed(2)} cm`} </Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("width")}:`}</span> &nbsp;
              <Typography>{`${width.toFixed(2)} cm`}</Typography>
            </Grid>
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}>{`${t("wrapping")}:`}</span> &nbsp;
              <Typography>{t(`wrapping_${wrapping}`)}</Typography>
            </Grid>
            {isDieCut && (
              <Grid item container xs={10} alignContent="center">
                <Typography>{t("has.die.cut")}</Typography>
              </Grid>
            )}
            <Grid item container xs={10} alignContent="center">
              <span style={spanStyle}> {`${t("price")}:`} </span> &nbsp;
              <Typography> {`${currentPrice} RON`} </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={5} md={3} alignContent="center">
          <TextField
            defaultValue={quantityState}
            onChange={(event) => setQuantityState(event.target.value)}
            label={t("quantity")}
          />
        </Grid>

        <Grid item container xs={6} md={2} justifyContent="center">
          <Button
            variant="contained"
            color="dark"
            onClick={handleReorder}
            sx={{
              border: "1px solid black",
              marginLeft: { xs: "10px", md: "0" },
            }}
          >
            <ShoppingCartIcon />
            {i18next.t("button.reorder")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
