import { createSlice } from "@reduxjs/toolkit";

export const userSliceReducer = createSlice({
  name: "user",
  initialState: {
    formData: {
      user: {},
      authenticated: false,
    },
  },
  reducers: {
    changeUserValues: (state, action) => {
      state.formData[action.payload.field] = action.payload.value;
    },
  },
});

export const { changeUserValues } = userSliceReducer.actions;
export const selectUserData = (state) => state.user.formData;

export default userSliceReducer.reducer;
